<template>
  <div class="expend-analysis">
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <DfEmplSelect
          ref="DfEmplSelect"
          size="mini"
          :emplGuids="submitData.EmplGuids"
          :storeList="storeList"
          :menuMultiple="true"
          :dutyMultiple="true"
          :emplMultiple="true"
          @selected="onSelectedEmpl"
        ></DfEmplSelect>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>

        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
        >
          导出表格
        </el-button>
      </div>

      <div class="df-col tips">
        <i class="el-icon-warning-outline"></i>
        <span>按照员工查看时将显示员工所跟进的所有顾客</span>
      </div>
    </div>

    <el-table
      id="exprot-table"
      :data="tableList"
      border
      stripe
      :max-height="tableMaxHeight"
      v-loading="loading"
      @sort-change="onSortChange"
    >
      <el-table-column prop="SortNum" sortable label="排名" width="80">
      </el-table-column>
      <el-table-column prop="MemberName" label="会员姓名"> </el-table-column>
      <el-table-column prop="MemberPhone" label="会员电话"> </el-table-column>
      <el-table-column prop="FollowName" label="跟进员工"> </el-table-column>
      <el-table-column
        prop="TotalPayMoney"
        sortable="custom"
        label="消费金额(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalPayMoneyCount"
        sortable="custom"
        label="消费次数(次)"
      >
      </el-table-column>
      <el-table-column prop="UnitPrice" sortable="custom" label="客单价(元/次)">
      </el-table-column>
      <el-table-column
        prop="TotalKeepPay"
        sortable="custom"
        label="消耗卡金(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalDonaPay"
        sortable="custom"
        label="消耗赠金(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalOpenCard"
        sortable="custom"
        label="购买套餐额(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalConsume"
        sortable="custom"
        label="消费套餐额(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalConsumeCount"
        sortable="custom"
        label="套卡消耗次(次)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalKeepMoney"
        sortable="custom"
        label="充值卡金(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalDonaMoney"
        sortable="custom"
        label="赠送赠金(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalKeepInte"
        sortable="custom"
        label="赠送积分(元)"
      >
      </el-table-column>
      <el-table-column
        prop="TotalDebtPay"
        sortable="custom"
        label="累计欠款(元)"
      >
      </el-table-column>
      <el-table-column prop="LastExpeTime" label="最后消费" sortable="custom" width="160">
        <template slot-scope="scope">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scope.row.LastExpeTime * 1000)
            )
          }}
        </template>
      </el-table-column>
    </el-table>

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40]"
      :page-size="submitData.PageSize"
      :current-page="submitData.PageIndex"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->
  </div>
</template>

<script>
import Report from "@/api/report.js";

export default {
  data() {
    return {
      tableMaxHeight: "",
      totalRecord: 0,
      submitData: {
        BeginDate: "",
        EndDate: "",
        PageSize: 20,
        PageIndex: 1,
        EmplGuids: [],
      },
      storeList: [],
      tableList: [],
      multipleDate: [],
      recordList: [],
      copyRecordList: [],
      loading: false,
      isFixedRow: true,
      excelDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.initMemberExpenseStatis();
    await this.queryMemberExpenseStatis();
    await this.onPaging();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 初始化会员消费分析
    async initMemberExpenseStatis() {
      try {
        let { data } = await Report.initMemberExpenseStatis();
        this.storeList = data.emplSelector.StoreList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取会员消费分析数据
    async queryMemberExpenseStatis() {
      this.loading = true;
      try {
        let { data } = await Report.queryMemberExpenseStatis(this.submitData);
        this.recordList = JSON.parse(JSON.stringify(data.RecordList));
        this.copyRecordList = data.RecordList;
        this.totalRecord = data.Filter.TotalRecord;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 假分页
    onPaging() {
      let recordList = JSON.parse(JSON.stringify(this.copyRecordList));
      let totalRecord = this.totalRecord;
      let pageSize = this.submitData.PageSize;
      let pageIndex = this.submitData.PageIndex;
      let start = (pageIndex - 1) * pageSize;
      let end = pageIndex * pageSize;
      let list = recordList.slice(start, end);
      this.tableList = list;
    },

    // 查询按钮点击事件
    async onSearch() {
      this.submitData.PageIndex = 1;
      await this.queryMemberExpenseStatis();
      await this.onPaging();
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 40);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.PageIndex = 1;
      this.submitData.PageSize = event;
      this.onPaging();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.PageIndex = event;
      this.onPaging();
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    // 员工选中事件
    onSelectedEmpl(event) {
      // console.log(event);
      this.storeList = event.storeList;
      this.submitData.EmplGuids = event.emplGuids;
    },

    // 排序
    onSortChange({ column, prop, order }) {
      console.log(column, prop, order);
      let recordList = JSON.parse(JSON.stringify(this.recordList)),
        arr = [];
      if (order == null) {
        arr = recordList;
      } else {
        arr = recordList.sort((a, b) => {
          if (order == "ascending") {
            return a[prop] - b[prop];
          } else if (order == "descending") {
            return b[prop] - a[prop];
          }
        });
      }

      this.copyRecordList = arr;
      this.submitData.PageIndex = 1;
      this.onPaging();
    },

    // 导出表格
    onExportExcel() {
      let { copyRecordList, tableList } = this,
        arr = JSON.parse(JSON.stringify(tableList));
      this.tableList = copyRecordList;
      this.excelDisabled = true;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "会员消费分析统计表");
        } finally {
          this.tableList = arr;
          this.excelDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.expend-analysis {
  height: 100%;
  .df-row {
    margin-top: 20px;

    .tips {
      .flex-row;

      .market-i(10px);
      i:hover {
        color: #ccc !important;
        cursor: unset !important;
      }

      span {
        margin-left: 4px;
        font-size: 12px;
        color: #999;
      }
    }
  }

  .el-pagination {
    margin-top: 20px;
    text-align: right;
  }
}

::v-deep .down-select {
  display: none !important;
}

@media (max-width: 1280px) {
  .expend-analysis {
    .df-row {
      .flex-row;
      // align-items: unset;
      flex-wrap: wrap;
      margin-block: 20px 10px;

      .df-col {
        margin-block: 0 10px;
        .el-date-editor {
          width: 240px;
        }
      }
    }
  }
}
</style>
